var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-news"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("“我是接班人”网络大课堂反响强烈！近700万人次观看")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_c('div',{staticClass:"pagination"},[_c('div',{staticClass:"a-more"},[_c('span',{on:{"click":function($event){return _vm.$router.push('/indexOs/newsDetails5')}}},[_vm._v("上一条：爱心点燃希望 真情传递温暖 ——新云网向花垣县双龙镇的十八洞小学进行捐赠")]),_c('span',{on:{"click":function($event){return _vm.$router.push('/indexOs/newsDetails7')}}},[_vm._v("下一条：长沙市这所小学打造智慧校园，极具未来感")])]),_c('el-button',{attrs:{"plain":""},on:{"click":function($event){return _vm.backGo()}}},[_vm._v(" 返回 ")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-setting"},[_c('h3',{staticClass:"time"},[_vm._v(" 发布时间： 2019-11-20 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 近日，中共中央、国务院印发《新时代爱国主义教育实施纲要》，提出要把青少年作为爱国主义教育的重中之重，将爱国主义精神贯穿于学校教育全过程，推动爱国主义教育进课堂、进教材、进头脑。 ")]),_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 湖南省充分发挥课堂教学主渠道作用，启动“我是接班人”网络大课堂建设，通过新云网公司提供的现代化信息设备与技术手段，把大课堂的教学内容资源共享，实现了经验交流和示范引领作用，创新爱国主义教育形式，增强教育的生动性、共享性、有效性。 ")]),_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 11月15日，湖南省网络大课堂“我是接班人”第一课《我和我的祖国》正式开课，通过网络大课堂系统进行全方位的互动，增强了教师与学生的互动性。40分钟的课程讲述了开国大典、五星红旗的设计、“半条被子”等背后的故事，引导和帮助广大学生自觉把少年梦与中国梦、个人成长进步与祖国未来发展紧密联系起来，牢固树立“为中华崛起而读书”的远大志向。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"40px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news6/news1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticClass:"img-explain"},[_vm._v(" 长沙市育英小学 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"40px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news6/news2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticClass:"img-explain"},[_vm._v(" 怀化市新晃一中 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"40px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news6/news3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticClass:"img-explain"},[_vm._v(" 永州市新圩学校 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"40px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news6/news4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticClass:"img-explain"},[_vm._v(" 石门县新铺镇中心学校 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"40px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news6/news5.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticClass:"img-explain"},[_vm._v(" 永州市冷水滩梅湾小学 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"40px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news6/news6.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticClass:"img-explain"},[_vm._v(" 江永县冷水铺学校 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"40px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news6/news7.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin":"20px 0 40px"}},[_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 根据规划，“我是接班人”网络大课堂通过网络联校、电视、直播平台，面向全省中小学开设思政德育、心理健康、生命安全、文化艺术、法治教育、科学探索、家校共育等七大版块特色网络课程，计划每周一堂课，授课老师均为省级名师，采取“1+3+N”的模式实施：“1”为总校，设在湖南省教育电视台；“3”为互动分校，每堂课接入3个县市区的各 1 所学校（以农村学校和教学点为主）作为分校，与主校进行同步互动教学；“N”为全省其他学校，通过电视和直播平台同步收看网络课堂。 ")]),_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 随着时代的发展，信息化技术教学将会日新月异。各市州、县市区、各级学校参照省级模式在本地区本单位建设“我是接班人”网络大课堂，并在课程、内容和时间上保持一体性、互补性，打造省、市、县、校四级“我是接班人”网络大课堂体系。 ")])])
}]

export { render, staticRenderFns }